import {
  put, delay, call, takeLatest
} from 'redux-saga/effects'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import uuid from 'uuid'
import { Types } from '../../ducks/consultant'
import { getStuffResult } from '../../httpServices'

function* requestStuffResult(params) {
  const newParams = {
    optionId: get(params, 'optionId'),
    step: get(params, 'step'),
    consultantId: get(params, 'consultantId') ? `${ get(params, 'consultantId') }` : '',
    activityId: get(params, 'activityId') ? get(params, 'activityId') : uuid()
  }

  try {
    yield delay(500)

    const apiResponse = yield call(getStuffResult, newParams)

    yield put({
      type: Types.REQUEST_STUFF_RESULT_SUCCESS,
      payload: apiResponse,
      consultantId: get(params, 'consultantId'),
      activityId: get(params, 'activityId')
    })

    if (isFunction(params.callback)) {
      yield params.callback()
    }

  } catch (error) {
    yield put({
      type: Types.REQUEST_STUFF_RESULT_ERROR,
      error: error.toString()
    })
  }
}


export default [takeLatest(Types.REQUEST_STUFF_RESULT, requestStuffResult)]
