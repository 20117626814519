import React, { useEffect } from 'react'
import { RouterSwitch } from '@lerna-whitelabel/commons'
import { useLocation } from 'react-router-dom'
import GoogleAnalytics from 'react-ga'
import withLayout from '../../layouts'
import routes from '../../routes/guest'

const GuestScreenRouter = (props) => {
  const location = useLocation()
  useEffect(() => {
    GoogleAnalytics.pageview(location.pathname + location.search)
  }, [location])

  return <RouterSwitch routes={ routes } { ...props } />
}

export default withLayout('guest')(GuestScreenRouter)
