import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './style'

const Footer = (props) => {
  const classes = useStyles()
  const { className, children } = props

  return (
    <div className={ [className, classes.container].join(' ') }>{ children }</div>
  )
}

Footer.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.array
}

Footer.defaultProps = {
  children: null
}

export default Footer
