import { lazy } from 'react'

const ScreenDefault = lazy(() => import('../screens/guest/ScreenDefault'))
const ScreenResult = lazy(() => import('../screens/guest/ScreenResult'))

const routes = {
  home: {
    path: '/',
    exact: true,
    Component: ScreenDefault
  },
  result: {
    path: '/result',
    exact: true,
    Component: ScreenResult
  }
}

export default routes
