import { all } from 'redux-saga/effects'
import consultant from './consultant'
import lists from './lists'

export default function* root() {
  yield all([
    ...consultant,
    ...lists
  ])
}
