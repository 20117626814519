import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '@lerna-whitelabel/styles'
import pagebg from '../../assets/images/background/pagebg/pagebg.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
    overflowX: 'hidden'
  },
  contentScroll: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    background: `url(${ pagebg })`,
    position: 'relative',
    overflowX: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    overflowX: 'hidden',
    minHeight: '100vh'
  },
  logoWrapper: {
    marginTop: '35px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    width: '100px'
  },
  lettuce: {
    zIndex: 0,
    position: 'absolute',
    left: '-14vw',
    top: '-33vw',
    width: '55vw',
    [theme.breakpoints.up('sm')]: {
      width: '37vw',
      left: '-16vw',
      top: '-22vw'
    }
  },
  sunflower: {
    zIndex: 1,
    position: 'absolute',
    left: '-18vw',
    top: '-4vw',
    width: '37vw',
    [theme.breakpoints.up('sm')]: {
      width: '20vw',
      top: '-1vw',
      left: '-9vw'
    }
  },
  tomato: {
    position: 'absolute',
    right: '-21vw',
    top: '-29vw',
    width: '60vw',
    [theme.breakpoints.up('sm')]: {
      width: '33vw',
      right: '-8vw',
      top: '-16vw'
    }
  },
  carrot: {
    position: 'absolute',
    right: '-18vw',
    width: '32vw',
    top: '19vw',
    [theme.breakpoints.up('sm')]: {
      width: '11vw',
      right: '-6vw',
      top: '11vw'
    }
  },
  seed: {
    position: 'absolute',
    top: '92vw',
    right: '0vw',
    width: '23vw',
    [theme.breakpoints.up('sm')]: {
      top: '25vw',
      right: '-4vw',
      width: '11vw'
    },
    '@media (min-width: 360px) and (max-width: 600px)': {
      top: '319px',
      right: '0vw',
      width: '20vw'
    }
  },
  mascot: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 180,
      maxHeight: 180
    },
    maxWidth: 107,
    maxHeight: 175
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '89%',
    marginLeft: '10px',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      marginLeft: '0',
      width: '100%',
      alignItems: 'flex-end'
    }
  },
  headerTextWrapper: {
    fontSize: '20px',
    marginLeft: '15px',
    maxWidth: '22ch',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '24ch'
    }
  },
  headerTextParagraph: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '91%',
      fontSize: '20px'
    }
  },
  bgWrapperImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    background: Colors.secondary,
    color: Colors.white
  },
  footerText: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginRight: '7px'
  }
}))

export default useStyles
