import {
  call, delay, put, takeLatest
} from 'redux-saga/effects'
import { Types } from '../../ducks/lists'
import { getLists } from '../../httpServices'

function* requestLists(params) {
  try {
    const {
      page
    } = params

    yield delay(500)

    const apiResponse = yield call(getLists, page)

    yield put({
      type: Types.REQUEST_LISTS_SUCCESS,
      payload: apiResponse
    })

  } catch (error) {
    yield put({
      type: Types.REQUEST_LISTS_ERROR,
      error: error.toString()
    })
  }
}


export default [takeLatest(Types.REQUEST_LISTS, requestLists)]
