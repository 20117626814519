import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Header } from '@lerna-whitelabel/commons'
import shareeat from '../../assets/images/shareEat/shareEat.png'
import {
  Lettuce,
  SunFlower,
  Tomato,
  Carrot,
  Seed,
  Mascot,
  Logo
} from '../../assets/images'
import Footer from '../../components/Footer'
import useStyles from './styles'

const GuestLayout = (props) => {
  const classes = useStyles()
  const { children } = props
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <div className={ classes.container }>
      <div className={ classes.contentScroll }>
        <div className={ classes.contentContainer }>
          <Header className={ classes.header }>
            <div className={ classes.bgWrapperImage }>
              <img src={ Lettuce } alt='lettuce' className={ classes.lettuce } />
            </div>
            <div className={ classes.bgWrapperImage }>
              <img
                src={ SunFlower }
                alt='sunflower'
                className={ classes.sunflower }
              />
            </div>
            <div className={ classes.bgWrapperImage }>
              <img src={ Tomato } alt='tomato' className={ classes.tomato } />
            </div>
            <div className={ classes.bgWrapperImage }>
              <img src={ Carrot } alt='carrot' className={ classes.carrot } />
            </div>
            <div className={ classes.bgWrapperImage }>
              <img
                src={ Seed }
                alt='seed'
                className={ classes.seed }
                style={
                  {
                    top: window.location.pathname.includes('result') && size[0] < 768 ? '217px' : false
                  }
                }
              />
            </div>
            <div className={ classes.logoWrapper }>
              <img src={ Logo } alt='logo da ISLA' className={ classes.logo } />
            </div>
            <div className={ classes.headerWrapper }>
              <div>
                <img src={ Mascot } alt='mascot' className={ classes.mascot } />
              </div>
              <div className={ classes.headerTextWrapper }>
                <p className={ classes.headerTextParagraph }>
                  <b>Oi, eu sou o Sementito :)</b>
                </p>
                <p>
                  Vou te ajudar a escolher a semente certa para você plantar.
                </p>
              </div>
            </div>
          </Header>
          {children}
          <br />
          <Footer className={ classes.footer }>
            <Typography align='center' className={ classes.footerText }>
              Por
            </Typography>
            <img src={ shareeat } alt='Logo da Share Eat' />
          </Footer>
        </div>
      </div>
    </div>
  )
}

GuestLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default GuestLayout
