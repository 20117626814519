export default {
  fontFamily: ["'myriad-pro-condensed'", "'sans-serif'"].join(','),

  fontWeight: {
    light: '300',
    regular: '400',
    medium: '500',
    bold: '700'
  },

  fontSize: {
    SSS: 10,
    SS: 13,
    S: 14,
    M: 16,
    L: 18,
    XL: 20,
    XXL: 24,
    XXXL: 36,
    XXXXL: 50,
    IconXXL: 64
  }
}
