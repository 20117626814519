import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './style'

const Header = (props) => {
  const classes = useStyles()
  const { className, children } = props

  return (
    <div
      className={ [className, classes.container].join(' ') }
    >
      { children }
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  className: PropTypes.string
}

Header.defaultProps = {
  className: ''
}

export default Header
