import React from 'react'
import PropTypes from 'prop-types'
import MuiGrid from '@material-ui/core/Grid'

const Grid = (props) => {
  const {
    children,
    ...componentProps
  } = props

  return (
    <MuiGrid
      { ...componentProps }
    >
      { children }
    </MuiGrid>
  )
}

Grid.propTypes = {
  children: PropTypes.any.isRequired
}

Grid.defaultProps = {}


export default Grid
