import React from 'react'
import PropTypes from 'prop-types'
import {
  CarouselProvider, Slider, Slide, ButtonBack, ButtonNext
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import map from 'lodash/map'
import size from 'lodash/size'
import useStyles from './styles'


const Carousel = (props) => {
  const {
    children,
    naturalSlideWidth,
    naturalSlideHeight,
    visibleSlides,
    hasMasterSpinner,
    infinite,
    className,
    classNameSlider,
    classNameSlide
  } = props

  const classes = useStyles()

  return (
    <CarouselProvider
      naturalSlideWidth={ naturalSlideWidth }
      naturalSlideHeight={ naturalSlideHeight }
      totalSlides={ size(children) }
      visibleSlides={ visibleSlides }
      hasMasterSpinner={ hasMasterSpinner }
      infinite={ infinite }
      className={ [className, classes.root].join(' ') }
    >
      <Slider
        className={ [classNameSlider, classes.slider].join(' ') }
      >
        {
          map(children, (item, index) => (
            <Slide
              key={ index }
              index={ index }
              className={ [classNameSlide, classes.slide].join(' ') }
            >
              { item }
            </Slide>
          ))
        }
      </Slider>
      <ButtonBack>Back</ButtonBack>
      <ButtonNext>Next</ButtonNext>
    </CarouselProvider>
  )
}

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  naturalSlideWidth: PropTypes.number,
  naturalSlideHeight: PropTypes.number,
  visibleSlides: PropTypes.number,
  hasMasterSpinner: PropTypes.bool,
  infinite: PropTypes.bool,
  className: PropTypes.string,
  classNameSlider: PropTypes.string,
  classNameSlide: PropTypes.string
}

Carousel.defaultProps = {
  naturalSlideWidth: 300,
  naturalSlideHeight: 300,
  visibleSlides: 1,
  hasMasterSpinner: false,
  infinite: false,
  className: '',
  classNameSlider: '',
  classNameSlide: ''
}

export default Carousel
