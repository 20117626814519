import { createActions, createReducer } from 'reduxsauce'


export const { Types, Creators } = createActions({
  resetStore: [],
  requestStuff: [],
  requestStuffSuccess: [],
  requestStuffError: ['error'],
  requestStuffResult: [],
  requestStuffResultSuccess: [],
  requestStuffResultError: ['error']
})


const INITIAL_STATE = {
  loading: false,
  error: null
}


const resetStore = (state = INITIAL_STATE) => ({
  state
})

const requestStuff = (state = INITIAL_STATE, { consultantId, activityId }) => ({
  ...state,
  consultantId,
  activityId,
  loading: true,
  error: INITIAL_STATE.error
})

const requestStuffSuccess = (state = INITIAL_STATE, {
  payload, consultantId, activityId, error
}) => ({
  ...state,
  consultantId,
  activityId,
  data: payload,
  loading: false,
  error
})

const requestStuffError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  loading: false,
  error
})

const requestStuffResult = (state = INITIAL_STATE, { consultantId, activityId }) => ({
  ...state,
  consultantId,
  activityId,
  loading: true,
  error: INITIAL_STATE.error
})

const requestStuffResultSuccess = (state = INITIAL_STATE, { error, payload }) => ({
  ...state,
  data: payload,
  loading: false,
  error
})

const requestStuffResultError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  loading: false,
  error
})


export default createReducer(INITIAL_STATE, {
  [Types.RESET_STORE]: resetStore,
  [Types.REQUEST_STUFF]: requestStuff,
  [Types.REQUEST_STUFF_SUCCESS]: requestStuffSuccess,
  [Types.REQUEST_STUFF_ERROR]: requestStuffError,
  [Types.REQUEST_STUFF_RESULT]: requestStuffResult,
  [Types.REQUEST_STUFF_RESULT_SUCCESS]: requestStuffResultSuccess,
  [Types.REQUEST_STUFF_RESULT_ERROR]: requestStuffResultError
})
