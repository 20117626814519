import {
  put, delay, call, takeLatest
} from 'redux-saga/effects'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import uuid from 'uuid'
import GoogleAnalytics from 'react-ga'
import { Types } from '../../ducks/consultant'
import { getStuff } from '../../httpServices'

import { AnalyticsActions, AnalyticsEvents } from '../../constants/Analytics'

function* requestStuff(params) {
  const newParams = {
    optionId: get(params, 'optionId'),
    step: get(params, 'step'),
    consultantId: get(params, 'consultantId') ? `${ get(params, 'consultantId') }/tip` : '',
    activityId: get(params, 'activityId') ? get(params, 'activityId') : uuid(),
    choices: get(params, 'choices')
  }

  if (newParams.step !== 1 && (params.option?.name || params.option?.label)) {
    GoogleAnalytics.event({
      category: AnalyticsEvents.CONSULTANT,
      action: AnalyticsActions.STEP_SELECTED(newParams.step - 1),
      label: params.option?.name || params.option?.label,
    })
  }

  try {
    yield delay(500)

    const apiResponse = yield call(getStuff, newParams)

    yield put({
      type: Types.REQUEST_STUFF_SUCCESS,
      payload: { ...apiResponse, choices: apiResponse.choices ? Object.values(apiResponse.choices) : [] },
      consultantId: get(params, 'consultantId'),
      activityId: get(newParams, 'activityId'),
    })

    if (isFunction(params.callback)) {
      yield params.callback()
    }

  } catch (error) {
    yield put({
      type: Types.REQUEST_STUFF_ERROR,
      error: error.toString()
    })
  }
}


export default [takeLatest(Types.REQUEST_STUFF, requestStuff)]
