import React from 'react'
import { StoreProvider } from '@lerna-whitelabel/store'
import withJssThemeProvider from './styles/withJssThemeProvider'
import ScreenRouter from './screens/ScreenRouter'
import defaultTheme from './themes/default'

function App() {
  return (
    <StoreProvider>
      <ScreenRouter />
    </StoreProvider>
  )
}

export default withJssThemeProvider(defaultTheme)(App)
