import {
  call, delay, put, takeLatest
} from 'redux-saga/effects'
import isFunction from 'lodash/isFunction'
import get from 'lodash/get'
import { Types } from '../../ducks/lists'
import { getList } from '../../httpServices'

function* requestSingleList(params) {
  try {

    if (isFunction(params.callBackList)) {
      params.callBackList(get(params, 'activityId'))
    }

    const apiResponse = yield call(getList, params)

    yield put({
      type: Types.REQUEST_SINGLE_LIST_SUCCESS,
      payload: apiResponse
    })

  } catch (error) {
    yield put({
      type: Types.REQUEST_SINGLE_LIST_ERROR,
      error: error.toString()
    })
  }
}


export default [takeLatest(Types.REQUEST_SINGLE_LIST, requestSingleList)]
