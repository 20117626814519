import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  resetStore: [],
  requestLists: [],
  requestListsSuccess: [],
  requestListsError: ['error'],
  requestSingleList: [],
  requestSingleListSuccess: [],
  requestSingleListError: ['error']
})

const INITIAL_STATE = {
  loading: false,
  error: null
}


const resetStore = (state = INITIAL_STATE) => ({
  state
})


const requestLists = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: INITIAL_STATE.error
})

const requestListsSuccess = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  ...payload,
  loading: false
})

const requestListsError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  loading: false,
  error
})

const requestSingleList = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: INITIAL_STATE.error
})

const requestSingleListSuccess = (state = INITIAL_STATE, { payload, callback }) => ({
  ...state,
  ...payload,
  callback,
  loading: false
})

const requestSingleListError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  loading: false,
  error
})

export default createReducer(INITIAL_STATE, {
  [Types.RESET_STORE]: resetStore,
  [Types.REQUEST_LISTS]: requestLists,
  [Types.REQUEST_LISTS_SUCCESS]: requestListsSuccess,
  [Types.REQUEST_LISTS_ERROR]: requestListsError,
  [Types.REQUEST_SINGLE_LIST]: requestSingleList,
  [Types.REQUEST_SINGLE_LIST_SUCCESS]: requestSingleListSuccess,
  [Types.REQUEST_SINGLE_LIST_ERROR]: requestSingleListError
})
