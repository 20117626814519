import axios from 'axios'
import getL from 'lodash/get'
import uuid from 'uuid'

const params = (newParams = {}) => {
  const { step = 1, ...others } = newParams

  return {
    step,
    ...others,
    userId: uuid(),
    // activityId: uuid()
  }
}

const getStuff = async (payload) => {
  try {
    const { data } = await axios.post(`${ process.env.REACT_APP_API_URL }${ getL(payload, 'consultantId') }`, {
      headers: {
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
        ContentType: process.env.REACT_APP_CONTENT_TYPE
      },
      ...params(payload)
    })

    return data
  } catch (e) {
    throw e
  }
}

const getLists = async (page = 1) => {
  try {
    const { data } = await axios.get(process.env.REACT_APP_API_LIST_URL, {
      headers: {
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
        // ContentType: process.env.REACT_APP_CONTENT_TYPE
      },
      params: {
        listType: 'roteiros',
        page
      }
    })

    return data
  } catch (e) {
    throw e
  }
}

const getList = async (paramsList, page = 1) => {
  const {
    uuid: uuidReceived
  } = paramsList

  try {
    const { data } = await axios.get(`${ process.env.REACT_APP_API_LIST_URL }/${ uuidReceived }`, {
      headers: {
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
      params: {
        page
      }
    })

    return data
  } catch (e) {
    throw e
  }
}

const sendRating = async (rating, activityId, consultantId = null) => {
  try {
    const { data } = await axios.post(
      `${ process.env.REACT_APP_API_URL }/${ rating }`,
      {
        activityId,
        ...params()
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

const getStuffResult = async (payload) => {
  const {
    consultantId,
    activityId
  } = payload

  try {
    const { data } = await axios.get(`${ process.env.REACT_APP_API_URL }${ consultantId }/result/${ activityId }`,
      {
        ...params(payload)
      },
      {
        headers: {
          Authorization: process.env.REACT_APP_AUTH_TOKEN,
          // ContentType: process.env.REACT_APP_CONTENT_TYPE
        }
      })

    return data

  } catch (e) {
    throw e
  }
}


export {
  getStuff,
  getList,
  getLists,
  sendRating,
  getStuffResult
}
